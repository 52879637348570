import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import FaqComponent from "../../FaqComponent/FaqComponent";
import { TranslationKey } from "src/types/translationKey";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const FaqDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const faqItems = React.useMemo(
    () =>
      Array.from({ length: 6 }, (_, index) => ({
        question: `faq.finishAccount.question_${index + 1}` as TranslationKey,
        answer: `faq.finishAccount.answer_${index + 1}` as TranslationKey
      })),
    []
  );

  return (
    <InDialog
      popup
      title={translate("gettingStarted")}
      onClose={onClose}
      returnUrl={returnUrl}
    >
      <Container>
        <FaqComponent faqItems={faqItems} title="faq.finishAccount.title" />
      </Container>
    </InDialog>
  );
};

export default FaqDialog;
